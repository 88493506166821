<template>
  <div class="page-container">
    <van-notice-bar scrollable text="请先在捐赠记录中领取证书" />
    <div class="my-cert-list" v-if="certList.length > 0">
      <van-row v-for="(row,rid) in certList" :key="rid">
        <van-col :span="8" v-for="(item,idx) in row" :key="rid +``+ idx">
          <div class="my-cert-item">
            <img :src="item.image" @click="openCertOverlay(item.donate)" alt="">
            <van-button class="my-cert-button" plain color="red" round :to="{path:'/donate/'+item.donate}">捐赠详情</van-button>
          </div>
        </van-col>
      </van-row>
    </div>
    <div v-else class="my-cert-list">
      <van-empty description="暂无数据" />
    </div>
    <van-overlay :show="showCert" @click="showCert = false">
      <div class="cert-box">
        <img v-if="certImg" :src="certImg" alt="">
        <p class="cert-tip">长按证书保存到手机，和朋友分享。</p>
        <p class="cert-tip">点击空白处关闭</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getMyCertList } from '@/api/mine'
import { getCertImageByDonateNo } from '@/api/donate'
export default {
  data () {
    return {
      certList: [],
      showCert: false,
      certImg: null
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getMyCertList().then(response => {
        console.log(response)
        for (let i = 0; i < Math.floor(response.length / 3); i += 3) {
          let _tmp = []
          for (let j = 0; j < 3; j++) {
            _tmp.push(response[i * 3 + j])
          }
          this.certList.push(_tmp)
        }

        if (response.length % 3 !== 0) {
          let _tmp = []
          for (let k = 0; k < response.length % 3; k++) {
            _tmp.push(response[Math.floor(response.length / 3) + k])
          }
          this.certList.push(_tmp)
        }
        console.log(this.certList)
      })
    },
    openCertOverlay (donateNo) {
      this.showCert = true
      this.certImg = null
      getCertImageByDonateNo(donateNo).then(response => {
        this.certImg = response.image
      })
    }
  }
}
</script>

<style>
.my-cert-list .van-row {
  margin-bottom: 1rem;
}
.my-cert-list {
  padding: 1rem 0.5rem;
}

.my-cert-item {
  text-align: center;
}

.my-cert-item img {
  width: 90%;
}

.my-cert-button {
  height: 1.2rem !important;
  font-size: 0.8rem !important;
}

.cert-tip {
  text-align: center;
  font-size: 0.7rem;
  color: #ffffff;
}

.cert-box {
  width: 18rem;
  height: 32rem;
  margin: 0 auto;
  margin-top: 7rem;
}

.cert-box img {
  width: 18rem;
}
</style>
